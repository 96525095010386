export const getAllVetApi = function () {
  return fetch(`${process.env.GATSBY_API_URL}/public-vets`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error('Error:', error);
    });
};