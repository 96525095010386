/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Layout } from 'antd';

import FooterContact from 'components/FooterContact';
import IndexBanner from '../components/IndexBanner';
import GlobalStyle from '../configs/globalStyle';
import IndexForVets from '../components/IndexForVets';
import SEO from '../components/seo';
import Header from '../components/Header';
import IndexSubForVets from '../components/IndexSubForVets';
import IndexContact from '../components/IndexContact';
import VeterinariansBanner from '../components/VeterinariansBanner';

interface HomeProps {
  data: any;
}

const Home: FC<HomeProps> = ({ data }) => {
  const [activeKey, setActiveKey] = useState();

  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <SEO />
      <Header handleSetActiveKey={setActiveKey} activeKey={activeKey} />
      <Layout.Content>
        <GlobalStyle />
        <IndexBanner
          backgroundImage={data.background}
          backgroundImage2={data.backgroundBanner2}
        />
        <IndexForVets
          items={[
            {
              title: '',
              subTitle: 'Get convenient and affordable veterinary advice',
              description: `• &nbsp $30.00 AU/15 minute consultation; $60.00 AU/30 minute consultation. <br> 
                • &nbsp Video conference, phone call, text and voice message options. <br> 
                • &nbsp Upload documents, photos and videos for review by the veterinarian. <br> 
                • &nbsp Previous session information available to review.`,
              btnText: '',
              btnUrl: '#',
              image: data.service1,
            },
            {
              title: '',
              subTitle: 'Why use Phone A Vet?',
              description: `There are many situations where a visit to a veterinary clinic, or a
                home or a farm visit by a veterinarian, is not possible or warranted.
                Phone A Vet allows access to professional veterinary advice from
                your home, the field, or the roadside, on weekends, public holidays
                and after hours.`,
              btnText: '',
              btnUrl: '',
              image: data.service2,
            },
          ]}
        />
        <VeterinariansBanner />
        <IndexSubForVets
          backgroundImage={data.backgroundContact}
          backgroundImageResponsive={data.backgroundContact2}
        />
        <IndexContact />
      </Layout.Content>
      <Layout.Footer className="footer">
        <FooterContact />
      </Layout.Footer>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    background: file(relativePath: { eq: "PAV_Hero_Final copy_14.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    service1: file(relativePath: { eq: "ip-12-forvets-1-updated.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    service2: file(relativePath: { eq: "ip-12-forvets-2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    backgroundContact: file(
      relativePath: { eq: "PAV_background_contact.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    backgroundContact2: file(
      relativePath: { eq: "PAV_backround_contact_2.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    backgroundBanner2: file(
      relativePath: { eq: "PAV_banner_backgroud-2.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
