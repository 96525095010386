import styled from 'styled-components';
import theme from 'configs/theme';

export const VeterinariansBannerStyles = styled.div`
  margin: 48px 0px;

  .feature-content {
    padding: 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;

    .title {
      font-family: DM Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #29abe2;
      display: block;
      margin-bottom: 14px;
    }
    .sub-title {
      margin-bottom: 20px;
      margin-top: 20px;
      color: #242e33;
      letter-spacing: 1px;
      line-height: 50px;
      font-style: normal;
      font-weight: 700;
    }

    .description {
      font-family: DM Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      color: #36464c;
    }
  }

  .vet-section {
    padding: 0px 122px;
    margin-bottom: 20px;

    .ant-row {
      display: flex;
      justify-content: center;
    }
  }

  .vet-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    .btn-view {
      position: absolute;
      top: 35%;
      border: none;
      padding: 0;
      height: fit-content;
      background: transparent;
      box-shadow: none;
      color: #fff;
      display: none;
      text-align: center;

      .anticon {
        font-size: 20px;
        margin: 0px;
      }
    }

    .vet-img {
      width: 180px;
      height: 180px;
      border-radius: 100%;
      object-fit: cover;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d4eef9;

      .vet-icon {
        width: 100px;
        height: auto;
      }
    }

    .vet-name {
      font-size: 20px;
      font-style: normal;
      line-height: 32px;
      letter-spacing: -0.25px;
      text-align: center;
      font-weight: 500;
    }
  }

  .vet-content:hover {
    img {
      filter: brightness(50%);
    }

    .btn-view {
      display: inline-block;
    }

    .vet-img {
      background: #1b191969;
    }
  }

  .btn-div {
    text-align: center;
    .btn {
      background: ${theme.palette.primary};
      box-shadow: 0px 8px 16px rgba(41, 171, 226, 0.15);
      border-radius: 16px;
      width: fit-content;
      border: none;
      margin: 10px 0px;
    }
  }
`;
