import styled from 'styled-components';
import theme from '../../configs/theme';

export const IndexSubForVetsWrapper = styled.div`
  background-color: ${theme.background.primary};
  position: relative;
  padding: 80px 120px;
  .group-item {
    display: flex;
    align-item: center;
    justify-content: flex-start;

    .title-item {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .description {
      font-family: DM Sans;
      font-style: normal;
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 0.5px;
      color: #ffffff;
    }
    .image {
      margin: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 24px;
    .group-item {
      flex-direction: column;
      align-items: center;
      .title-item {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
      }

      .description {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
      }
      .image {
        margin: 30px;
        width: 40px;
        height: 40px;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 40px;
    .content {
      margin: 0;
      max-width: 100%;
    }
  }
`;
