import React from 'react';
import { Row, Col, Grid, Button } from 'antd';
import Img from 'gatsby-image';
import { IndexSubForVetsWrapper } from './styles';
import iconAward from 'images/icons/ic_award.svg';
import iconClipboard from 'images/icons/ic_clipboard.svg';
import iconTag from 'images/icons/ic_tag.svg';
import { IndexContactWrapper } from 'components/IndexContact/styles';

interface IndexSubForVetsProps {
  backgroundImage: any;
  backgroundImageResponsive: any;
}

const listItem = [
  {
    image: iconTag,
    title: 'Affordable rates per session - AU $24.95',
  },
  {
    image: iconClipboard,
    title: 'Developed by veterinary & technology professionals',
  },
  {
    image: iconAward,
    title: 'Conveniently access high quality veterinary advice.',
  },
];

const IndexSubForVets: FC<IndexSubForVetsProps> = ({
  backgroundImage,
  backgroundImageResponsive,
}) => {
  const screens = Grid.useBreakpoint();
  return (
    <>
      <IndexSubForVetsWrapper>
        <Row gutter={[120, 16]} justify="center" align="top">
          {/* {listItem.map((item: object, key: number) => ( */}
          <Col span={24} className="group-item">
            <img src={iconAward} className="image" />
            <div>
              <div className="title-item">Phone A Vet origins</div>
              <div className="description">
                Phone A Vet is Australian made, owned, and operated. It was
                developed in Australia by a team of veterinary and technology
                professionals, all animal owners, who recognised the need for
                people to get good veterinary advice, remotely. And it had to be
                an easy, affordable process.
              </div>
            </div>
          </Col>
          {/* ))} */}
        </Row>
      </IndexSubForVetsWrapper>
      <IndexContactWrapper className="sub-forvets">
        <Row className="row-contact-phone">
          <Col md={12} lg={12} sm={12}>
            {screens.xl && (
              <Img
                objectFit="contain"
                fluid={backgroundImage?.childImageSharp?.fluid}
                style={{ maxWidth: 500 }}
              />
            )}
            {!screens.xl && (
              <Img
                objectFit="contain"
                fluid={backgroundImageResponsive?.childImageSharp?.fluid}
                style={{ maxWidth: 500 }}
              />
            )}
          </Col>
          <Col md={12} lg={12} sm={12}>
            <div className="content">
              {/* <div className="sub-title">Veternarian Information</div> */}
              <div className="t-bold-40px-50 main-title">For veterinarians</div>
              <div className="sub-content">
                Are you an Australian registered veterinarian interested in
                working with Phone A Vet?
              </div>
              <div className="t-normal-20px-36 main-content">
                The app is intuitive, convenient, and fun to use. Doing a
                consult, keeping a record, and getting paid is painless. Click
                the button below to find out more.
              </div>
              <div className="btn-div">
                <Button className="btn" href="/registration-information/">Learn more</Button>
              </div>
            </div>
          </Col>
        </Row>
      </IndexContactWrapper>
    </>
  );
};

export default IndexSubForVets;
